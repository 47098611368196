module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"15","matomoUrl":"https://piwik.thomaskuhnert.com/matomo.php","siteUrl":"https://hartzfacts.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Noto Serif TC"],"urls":["static/fonts/fonts.css"]}},
    }]
